import { PlusIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BASE_URI } from '../../../assets/app.config';
import withLayout from '../../../hoc/withLayout';
import Loader from '../../Shared/Loader';

const Thead = () => {
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        >
          Nom complet
        </th>

        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        >
          Téléphone
        </th>
        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        >
          Matricule
        </th>
        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        >
          Email
        </th>
        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        >
          Abonnement
        </th>
        <th scope='col' className='relative px-6 py-3'></th>
      </tr>
    </thead>
  );
};

const Tbody = ({ users, search }) => {
  const history = useHistory();
  return (
    <tbody>
      {users.map((user) => {
        const limitDate = user?.activeAccount?.limitDate
          ? new Date(user?.activeAccount?.limitDate).getTime() -
            new Date().getTime()
          : null;
        // const reg = new RegExp(`${search}`, 'ig');
        // if (
        //   search.length >= 3 &&
        //   !user?.lastname?.match(reg) &&
        //   !user?.firstname?.match(reg)
        // ) {
        //   return null;
        // }
        return (
          <tr key={user._id} className='bg-white'>
            <td className='px-6 py-4 whitespace-wrap text-sm font-medium text-gray-900 capitalize'>
              {user.lastname + ' ' + user.firstname}
            </td>

            <td className='px-6 py-4 whitespace-wrap text-sm text-gray-500'>
              {user.mobilePhone
                ? `+${user.mobilePhone.prefix} ${user.mobilePhone.number}`
                : null}
            </td>
            <td className='px-6 py-4 whitespace-wrap text-sm text-gray-500'>
              {user.matricule}
            </td>
            <td className='px-6 py-4 whitespace-wrap text-sm text-gray-500'>
              {user.email}
            </td>
            <td className='px-6 py-4 whitespace-wrap text-sm text-gray-500 capitalize'>
              <span
                className={` px-2 py-1 text-${
                  !!!limitDate
                    ? 'gray'
                    : limitDate > 0
                    ? 'green'
                    : limitDate <= 0 && 'red'
                }-600 py-1 px-1 border border-${
                  !!!limitDate
                    ? 'gray'
                    : limitDate > 0
                    ? 'green'
                    : limitDate <= 0 && 'red'
                }-600 bg-${
                  !!!limitDate
                    ? 'gray'
                    : limitDate > 0
                    ? 'green'
                    : limitDate <= 0 && 'red'
                }-100 rounded-full font-medium hover:text-${
                  !!!limitDate
                    ? 'gray'
                    : limitDate > 0
                    ? 'green'
                    : limitDate <= 0 && 'red'
                }-900`}
              >
                {limitDate > 0
                  ? 'En Cours'
                  : !!!limitDate
                  ? 'Pas définie'
                  : limitDate <= 0 && 'Epuisé'}
              </span>
            </td>
            <td className='px-6 py-4 whitespace-wrap text-right text-sm font-medium'>
              <button
                className='text-blue-600 py-1 px-1 border border-blue-600 bg-blue-100 rounded-full font-medium hover:text-blue-900'
                onClick={() => history.push(`/account/user/${user._id}`)}
              >
                Editer
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const Users = ({ search }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const { data } = await axios.get(`${BASE_URI}users`);
      setUsers(data);
      setLoading(false);
    }
    getData();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className='flex flex-col'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <Thead />
              <Tbody users={users} search={search} />
            </table>
          </div>
        </div>
      </div>
      <button
        class='fixed right-10 bottom-5 p-0 w-12 h-12 bg-blue-600 rounded-full hover:bg-blue-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none'
        onClick={() => history.push('/account/add')}
      >
        <PlusIcon className='w-6 h-6 text-white inline-block' />
      </button>
    </div>
  );
};

export default withLayout(Users);
