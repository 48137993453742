import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import withLayout from '../../../hoc/withLayout';
import { BASE_URI } from '../../../assets/app.config';
import ComponentToast from '../../../assets/helpers/helperToast';
import { helperFirstLetterUpper } from '../../../assets/helpers/helperFirstLetterUpper';
import Loader from '../../Shared/Loader';
import SwitchActive from '../../Shared/SwitchActive';

import { Countries } from '../../../assets/helpers/helperCountriesList';
import { useHistory } from 'react-router';
import helperDate from '../../../assets/helpers/helperDate';

const InputLayout = ({
  type,
  name,
  value,
  onChange,
  required = false,
  label,
  width = '100%',
  disabled,
  minDate,
}) => {
  return (
    <div
      className='flex flex-col items-start justify-end my-2 '
      style={{ width: width }}
    >
      <label className='font-xs text-gray-500'>{label}</label>
      <input
        type={type}
        min={minDate}
        className={`w-full block mt-auto ${
          disabled && 'border-gray-300 bg-transparent'
        }`}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

const SelectLayout = ({
  options,
  width,
  name,
  onChange,
  value,
  label,
  disabled,
}) => {
  return (
    <div
      className='flex flex-col items-start justify-start my-2 '
      style={{ width: width }}
    >
      <label className='font-xs text-gray-500'>{label}</label>
      <select
        name={name}
        onChange={onChange}
        value={value}
        className={`w-full ${disabled && 'border-gray-300  bg-transparent'}`}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const PlanCard = ({
  manageLimitDate,
  prolongDateLimit,
  setSubscription,
  limitDate,
  form,
  changeSubcriptionPlan,
  onToggleActive,
}) => {
  const [showDate, setShowDate] = useState(false);
  const { days, hours, minutes, seconds, expired } = manageLimitDate;

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',
        height: 280,
        borderRadius: 4,
        padding: '1rem',
      }}
    >
      <div className='w-full flex items-center justify-start mb-7'>
        <span className='text-2xl font-bold'>
          {form?.activeAccount?.plan === 'free' ? 'Essaie Gratuit' : 'Premium'}
        </span>

        {form?.activeAccount?.plan === 'free' && (
          <button
            className='font-medium text-center bg-blue-500 px-6 py-2 text-sm font-medium leading-6 text-white font-medium rounded ml-auto'
            onClick={changeSubcriptionPlan}
          >
            Passer au Premium
          </button>
        )}
      </div>
      <div className='w-full  flex flex-col items-center'>
        <span
          className={`text-lg leading-4 text-center font-medium text-${
            expired ? 'red' : 'gray'
          }-${expired ? '500' : '900'} mb-2`}
        >
          {expired ? "Période d'essaie expirée !" : 'Expire dans :'}
        </span>
        <div className='w-full flex items-end justify-center'>
          <p
            class={`text-5xl font-bold text-${expired ? 'red' : 'black'}-${
              expired ? '500' : '900'
            } mr-1`}
          >
            {days}
          </p>
          <span className='text-sm font-medium mr-3'>j</span>
          <p
            class={`text-5xl font-bold text-${expired ? 'red' : 'black'}-${
              expired ? '500' : '900'
            } mr-1`}
          >
            {hours}
          </p>
          <span className='text-sm font-medium mr-3'>h</span>
          <p
            class={`text-5xl font-bold text-${expired ? 'red' : 'black'}-${
              expired ? '500' : '900'
            } mr-1`}
          >
            {minutes}
          </p>
          <span className='text-sm font-medium mr-3'>min</span>
          <p
            class={`text-5xl font-bold text-${expired ? 'red' : 'black'}-${
              expired ? '500' : '900'
            } mr-1`}
          >
            {seconds}
          </p>
          <span className='text-sm font-medium mr-3'>s</span>
        </div>
      </div>
      {showDate && (
        <div className='w-full flex items-center justify-end mt-16'>
          <InputLayout
            label=''
            type='date'
            minDate={helperDate(limitDate, 'YYYY-MM-DD')}
            onChange={(e) =>
              setSubscription((s) => ({
                ...s,
                limitDate: new Date(e.target.value),
              }))
            }
          />

          <button
            class='inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-blue-700 uppercase transition bg-transparent border-2 border-blue-700 rounded shadow hover:shadow-lg hover:bg-blue-100 focus:outline-none block ml-8'
            onClick={(e) => prolongDateLimit(e)}
          >
            Prolonger
          </button>
        </div>
      )}
      {!showDate && (
        <div className='w-full flex items-center justify-between mt-16'>
          <button
            onClick={(e) => onToggleActive(e, !form?.active)}
            className={`text-center bg-${
              form?.active ? 'red' : 'green'
            }-500 px-6 py-2 text-sm  leading-6 text-white font-medium rounded`}
          >
            {`${form?.active ? 'Désactiver' : 'Activer'} le compte`}
          </button>
          <button
            onClick={() => setShowDate(true)}
            class='inline-block px-6 py-2 text-sm font-medium leading-6 text-center text-blue-700 transition bg-transparent border-2 border-blue-700 rounded shadow hover:shadow-lg hover:bg-blue-100 focus:outline-none block '
          >
            Prolonger la date
          </button>
        </div>
      )}
    </div>
  );
};

const formatUserDate = (form) => {
  return {
    ...form,
    firstname: form.firstname.toLowerCase(),
    lastname: form.lastname.toLowerCase(),
    mobilePhone: {
      prefix: form.codeCountry,
      number: form.mobilePhone,
    },
    address: {
      country: form.country,
      zip: form.zip,
      city: form.city,
      number: form.number,
      street: form.street,
    },
  };
};
const User = ({ match }) => {
  const history = useHistory();
  const [form, setForm] = useState([]);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [subscription, setSubscription] = useState({
    plan: 'free',
    limitDate: null,
  });
  const [manageLimitDate, setManageLimitDate] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
    expired: false,
  });
  const { slug } = match.params;
  const countDownDate = new Date(form?.activeAccount?.limitDate).getTime();

  const countDownDateLimit = useCallback(() => {
    let onCountFunc;
    if (form?.activeAccount?.limitDate) {
      onCountFunc = setInterval(function () {
        let datenow = new Date().getTime();
        let distance = countDownDate - datenow;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setManageLimitDate((s) => ({ ...s, days, hours, minutes, seconds }));
        if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
          clearInterval(onCountFunc);
          setManageLimitDate((s) => ({
            ...s,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            expired: true,
          }));
        }
      }, 1000);
    }
  }, [countDownDate, form?.activeAccount?.limitDate]);

  const getData = useCallback(async () => {
    setLoading(true);
    const {
      data: { user },
    } = await axios.get(`${BASE_URI}user/by-id/${slug}`);
    setForm({
      ...user,
      firstname: helperFirstLetterUpper(user.firstname),
      lastname: helperFirstLetterUpper(user.lastname),
      ...user.address,
      mobilePhone: user.mobilePhone?.number,
      codeCountry: user.mobilePhone?.prefix,
    });
    setLoading(false);
  }, [slug]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    countDownDateLimit();
  }, [countDownDateLimit]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.put(
        `${BASE_URI}user/${slug}`,
        formatUserDate(form)
      );
      if (res.status === 200) {
        setMessage('Utilisateur modifié');
        setSuccess(true);
        setShow(true);
        history.goBack();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage('Une erreur est survenue');
      setSuccess(false);
      setShow(true);
    }
  };

  const prolongDateLimit = async (e) => {
    setShow(false);
    e.preventDefault();

    if (!!!subscription.limitDate) {
      setMessage('Veuillez saisir la date !');
      setSuccess(false);
      setShow(true);
      return;
    }
    setLoading(true);
    await axios
      .put(`${BASE_URI}user/${slug}`, {
        ...formatUserDate(form),
        activeAccount: {
          plan: form?.activeAccount?.plan,
          limitDate: subscription.limitDate,
        },
      })
      .then((_) => {
        setLoading(false);
        setMessage("Date d'éssaie prolongée !");
        setSuccess(true);
        setShow(true);
        history.goBack();
      })
      .catch((_) => {
        setLoading(false);
        setMessage('Une erreur est survenue');
        setSuccess(false);
        setShow(true);
      });
  };
  const changeSubcriptionPlan = (e) => {
    e.preventDefault();
    setLoading(true);
    const today = new Date();
    axios
      .put(`${BASE_URI}user/${slug}`, {
        ...formatUserDate(form),
        activeAccount: {
          ...form.activeAccount,
          plan: 'premium',
          limitDate: new Date(today.setDate(today.getDate() + 365.25)),
        },
      })
      .then((_) => {
        setLoading(false);
        setMessage("Plan d'abonnement modifié !");
        setSuccess(true);
        getData();
      })
      .catch((_) => {
        setLoading(false);
        setMessage('Une erreur est survenue');
        setSuccess(false);
        setShow(true);
      });
  };
  const onToggleActive = (e, value) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`${BASE_URI}user/${slug}`, { active: value })
      .then((_) => {
        setLoading(false);
        setMessage(`Compte ${value ? 'activé' : 'désactivé'} !`);
        setSuccess(true);
        getData();
      })
      .catch((_) => {
        setLoading(false);
        setMessage('Une erreur est survenue');
        setSuccess(false);
        setShow(true);
      });
  };

  if (loading) {
    return <Loader />;
  }

  const civilityData = [
    { label: 'Mr', value: 'mr' },
    { label: 'Mme', value: 'mme' },
    { label: 'Mlle', value: 'mlle' },
  ];
  const specialityData = [
    { label: 'Item 1', value: 'item 1' },
    { label: 'Item 2', value: 'item 2' },
    { label: 'Item 3', value: 'item 3' },
  ];

  const handleModuleActive = (module, active) => {
    const updatedModules = form?.modules?.map((m) => {
      if (m?.name === module?.name) {
        return { ...m, active };
      }
      return m;
    });

    setForm({ ...form, modules: updatedModules });
  };

  return (
    <>
      <div className='flex items-center justify-between w-full'>
        <ComponentToast
          show={show}
          setShow={setShow}
          message={message}
          success={success}
        />

        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          Informations Utilisateur
        </h3>
        <button
          class='inline-block px-4 py-2 text-xs font-medium text-center text-blue-700 uppercase transition bg-transparent border-2 border-blue-700 rounded-full shadow ripple hover:shadow-lg hover:bg-blue-100 focus:outline-none'
          onClick={() => setDisabled(!disabled)}
        >
          Modifiez
        </button>
      </div>
      <form
        className='mt-5 border-t border-gray-200 w-full flex flex-col items-start justify-start'
        onSubmit={handleSubmit}
      >
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='id'
            type='number'
            name='id'
            value={form['refNum']}
            onChange={handleChange}
            width='10%'
            disabled={disabled}
          />
          <InputLayout
            label='Code médecin'
            type='text'
            name='doctorCode'
            value={form['doctorCode']}
            onChange={handleChange}
            width='85%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <SelectLayout
            label='Civilité'
            name='civility'
            value={form['civility']}
            onChange={handleChange}
            options={civilityData}
            width='10%'
            disabled={disabled}
          />
          <InputLayout
            label='Nom'
            type='text'
            name='lastname'
            value={form['lastname']}
            onChange={handleChange}
            width='40%'
            disabled={disabled}
          />
          <InputLayout
            label='Prénom(s)'
            type='text'
            name='firstname'
            value={form['firstname']}
            onChange={handleChange}
            width='40%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Matricule'
            type='number'
            name='matricule'
            value={form['matricule']}
            onChange={handleChange}
            width='75%'
            disabled={disabled}
          />

          <InputLayout
            label='Date de naissance'
            type='date'
            name='dateOfBirth'
            value={form['dateOfBirth']}
            onChange={handleChange}
            width='20%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <SelectLayout
            label='Spécialité'
            name='speciality'
            value={form['speciality']}
            onChange={handleChange}
            options={specialityData}
            width='30%'
            disabled={disabled}
          />
          <InputLayout
            label='Téléphone Cabinet'
            type='text'
            name='cabinetPhone'
            value={form['cabinetPhone']}
            onChange={handleChange}
            width='30%'
            disabled={disabled}
          />
          <InputLayout
            label='Fax'
            type='number'
            name='fax'
            value={form['fax']}
            onChange={handleChange}
            width='30%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Téléphone Privé'
            type='number'
            name='privatePhone'
            value={form['privatePhone']}
            onChange={handleChange}
            width='48%'
            disabled={disabled}
          />
          <div
            style={{ width: '48%' }}
            className='flex flex-col items-start justify-start'
          >
            <label className='font-xs text-gray-500'>Tel/Mobile</label>
            <div className='w-full flex items-center'>
              <select
                name='codeCountry'
                className={`w-full ${
                  disabled && 'border-gray-300  bg-transparent'
                }`}
                style={{ width: '25%' }}
                required
                onChange={handleChange}
                value={form['codeCountry']}
                disabled={disabled}
              >
                {Countries.map((country, id) => (
                  <option key={id} value={country.phone}>
                    +{country.phone}
                  </option>
                ))}
              </select>
              <InputLayout
                type='text'
                name='mobilePhone'
                value={form['mobilePhone']}
                onChange={handleChange}
                width='75%'
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Email'
            type='email'
            name='email'
            value={form['email']}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='N°'
            type='number'
            name='number'
            value={form['number']}
            onChange={handleChange}
            width='20%'
            disabled={disabled}
          />
          <InputLayout
            label='Rue'
            type='text'
            name='street'
            value={form['street']}
            onChange={handleChange}
            width='75%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Code postal'
            type='number'
            name='zip'
            value={form['zip']}
            onChange={handleChange}
            width='20%'
            disabled={disabled}
          />
          <InputLayout
            label='Ville'
            type='text'
            name='city'
            value={form['city']}
            onChange={handleChange}
            width='75%'
            disabled={disabled}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <div className='flex flex-col items-start w-full '>
            <label className='font-xs text-gray-500'>Pays</label>

            <select
              name='country'
              className={`w-full ${
                disabled && 'border-gray-300  bg-transparent'
              }`}
              required
              onChange={handleChange}
              value={form['country']}
              disabled={disabled}
            >
              {Countries.map((country, id) => (
                <option key={id} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label="texte d'en-tête"
            type='text'
            name='headerText'
            value={form['headerText']}
            onChange={handleChange}
            width='100%'
            disabled={disabled}
          />
        </div>

        <span className='text-lg leading-4 font-medium text-gray-900 my-6'>
          Information Bancaire
        </span>
        {form?.rib?.map((rib, id) => (
          <div key={id} className='flex items-center justify-start w-full '>
            <span className='w-2/12 font-medium text-gray-500'>{rib.bic}</span>
            {'   '}
            <span className='w-10/12 font-medium text-gray-500'>
              {rib.iban}{' '}
            </span>
          </div>
        ))}

        <div className='w-full flex items-end justify-between my-4'>
          <div className='w-6/12  flex flex-col items-start justify-start'>
            <span className='text-lg leading-4 font-medium text-gray-900 my-6'>
              Modules
            </span>
            <div className='w-full flex flex-col items-start justify-start'>
              {form?.modules?.map((module, id) => {
                return (
                  <div
                    key={id}
                    className='flex items-center justify-between w-full h-16 bg-white rounded p-3 my-1'
                  >
                    <span className='font-xs text-gray-700 capitalize'>
                      {module.name === 'formsModule'
                        ? 'Formulaires'
                        : module.name === 'dentalModule'
                        ? 'MW Dentiste'
                        : module.name === 'cepha'
                        ? 'Céphalométrique'
                        : "Comparateur d'images"}
                    </span>
                    <SwitchActive
                      setLoading={setLoading}
                      setMessage={setMessage}
                      setSuccess={setSuccess}
                      setShow={setShow}
                      userId={form._id}
                      module={module}
                      handleModuleActive={handleModuleActive}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className='w-6/12 h-full  flex flex-col items-start justify-start  px-5 py-1'>
            <span className='text-lg leading-4 font-medium text-gray-900 my-6'>
              Abonnement
            </span>
            <PlanCard
              manageLimitDate={manageLimitDate}
              prolongDateLimit={prolongDateLimit}
              changeSubcriptionPlan={changeSubcriptionPlan}
              onToggleActive={onToggleActive}
              setSubscription={setSubscription}
              form={form}
              limitDate={form?.activeAccount?.limitDate}
            />
          </div>
        </div>

        <div className='w-full flex items-center justify-end'>
          <button
            type='button'
            onClick={() => history.goBack()}
            className='font-medium text-center text-gray-900 uppercase bg-gray-200 px-6 py-2 border-2 border-gray-200'
          >
            Retour
          </button>

          {!disabled && (
            <button class='inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-blue-700 uppercase transition bg-transparent border-2 border-blue-700 rounded shadow hover:shadow-lg hover:bg-blue-100 focus:outline-none block ml-8'>
              Mettre à jour
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default withLayout(User);
