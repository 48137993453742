import { BrowserRouter, Switch, Route } from "react-router-dom";
import Forget from "./components/offline/forget";
import Login from "./components/offline/login";
import Online from "./components/online";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route path='/forget' component={Forget} />
        <Route path='/account' component={Online} />
      </Switch>
    </BrowserRouter>
  );
}
