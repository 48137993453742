import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BASE_URI } from "../assets/app.config";
import Loader from "../components/Shared/Loader";

const withUserConnected = (WrappedComponent) => (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function checkConnexion() {
      try {
        const token = sessionStorage.getItem("md_token");
        if (!!!token) {
          history.push("/");
        } else {
          await axios.get(`${BASE_URI}check-auth-admin/${token}`);
          setLoading(false);
        }
      } catch (err) {
        history.push("/");
      }
    }
    checkConnexion();
  }, [history]);

  if (loading) {
    return <Loader />;
  }

  return <WrappedComponent {...props} />;
};

export default withUserConnected;
