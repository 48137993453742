import { Switch } from '@headlessui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BASE_URI } from '../../../assets/app.config';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SwitchActive = ({
  module,
  handleModuleActive,
  userId,
  setLoading,
  setMessage,
  setSuccess,
  setShow,
}) => {
  const [enabled, setEnabled] = useState(module.active);
  useEffect(() => {
    setEnabled(module.active);
  }, [module]);

  const handleActive = async (v) => {
    await axios
      .put(`${BASE_URI}user/module/by-name/${userId}`, {
        name: module.name,
        value: v,
      })
      .then((res) => {
        setLoading(false);
        setMessage(`Module ${v ? 'attribué' : 'retiré'} !`);
        setSuccess(true);
        setShow(true);
      })
      .catch((err) => {
        setLoading(false);
        setMessage('Une erreur est survenue');
        setSuccess(false);
        setShow(true);
      });
    setEnabled(v);
    handleModuleActive(module, v);
  };

  return (
    <Switch
      checked={enabled}
      onChange={handleActive}
      className='flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
    >
      <span
        aria-hidden='true'
        className='pointer-events-none absolute bg-white w-full h-full rounded-md'
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'bg-green-600' : 'bg-gray-200',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
        )}
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};

export default SwitchActive;
