import { countries } from "countries-list";
const CountriesList = () => {
  let array = [];
  for (let i in countries) {
    array.push({ name: countries[i].name, phone: countries[i].phone });
  }
  return array;
};

export const Countries = CountriesList();
