export const helperAtobToken = (key, index) => {
  if (sessionStorage.getItem(key) !== null) {
    const res = JSON.parse(
      window.atob(sessionStorage.getItem(key).split(".")[1])
    );
    if (index) {
      return res[index];
    }
    return res;
  }
};
