import axios from "axios";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URI } from "../../../assets/app.config";
import ComponentToast from "../../../assets/helpers/helperToast";
import Logo from "../../../assets/images/logo.svg";

const Forget = () => {
  const emailRef = useRef(null);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        `${BASE_URI}admin/send-email-reset-password`,
        {
          email: emailRef.current.value.toLowerCase(),
        }
      );
      if (res.status === 200) {
        setMessage(
          "Message envoyé avec succès, veuillez verifier votre boîte mail."
        );
        setSuccess(true);
        setShow(true);
      }
      setLoading(false);
    } catch (err) {
      setMessage("Email non trouvé");
      setSuccess(false);
      setShow(true);
      setLoading(false);
    }
  };
  return (
    <div className='min-h-screen  bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8'>
      <ComponentToast
        success={success}
        setShow={setShow}
        show={show}
        message={message}
      />
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img className='mx-auto h-12 w-auto' src={Logo} alt='logo' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Mot de passe oublié
        </h2>
      </div>

      <div className='mt-8  w-2/6 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form className='space-y-6' onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'>
                Email
              </label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  ref={emailRef}
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center'></div>

              <div className='text-sm'>
                <NavLink
                  to='/'
                  className='font-medium text-blue-600 hover:text-blue-500'>
                  Connexion
                </NavLink>
              </div>
            </div>

            <div>
              <button
                type='submit'
                disabled={loading}
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-grey-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                {loading ? "chargement..." : "Envoyer"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forget;
