import axios from 'axios';
import { useEffect, useState } from 'react';
import withLayout from '../../../hoc/withLayout';
import { BASE_URI } from '../../../assets/app.config';
import ComponentToast from '../../../assets/helpers/helperToast';
import Loader from '../../Shared/Loader';
import SwitchActive from '../../Shared/SwitchActive';
import { Countries } from '../../../assets/helpers/helperCountriesList';
import { useHistory } from 'react-router';

const InputLayout = ({
  type,
  name,
  value,
  onChange,
  required = false,
  label,
  width = '100%',
  requiredBg = false,
}) => {
  return (
    <div
      className='flex flex-col items-start justify-end my-2 '
      style={{ width: width }}
    >
      <label className='font-xs text-gray-500'>{label}</label>
      <input
        type={type}
        className={`w-full block mt-auto  ${
          requiredBg ? 'bg-red-300' : 'bg-transparent'
        }`}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

const SelectLayout = ({ options, width, name, onChange, label }) => {
  return (
    <div
      className='flex flex-col items-start justify-start my-2 '
      style={{ width: width }}
    >
      <label className='font-xs text-gray-500'>{label}</label>
      <select name={name} onChange={onChange} className='w-full bg-transparent'>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
const AddUser = () => {
  const inputs = {
    id: '',
    doctorCode: '',
    civility: 'mr',
    title: '',
    lastname: '',
    firstname: '',
    matricule: '',
    dateOfBirth: '',
    speciality: '',
    cabinetPhone: '',
    fax: '',
    privatePhone: '',
    mobilePhone: '',
    codeCountry: '',
    email: '',
    number: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    headerText: '',
    bic1: '',
    iban1: '',
    bic2: '',
    iban2: '',
    bic3: '',
    iban3: '',
    bic4: '',
    iban4: '',
  };

  const history = useHistory();

  const [form, setForm] = useState(inputs);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([
    { name: 'formsModule', active: false },
    { name: 'dentalModule', active: false },
    { name: 'cepha', active: false },
    { name: 'imageComparator', active: false },
  ]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const getId = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${BASE_URI}user/refnum`);
        setForm((f) => ({ ...f, id: data.refnum }));
      } catch (err) {}

      setLoading(false);
    };
    getId();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const rib = [];
    for (let i = 0; i <= 3; i++) {
      rib.push({
        bic: form['bic' + (i + 1)],
        iban: form['iban' + (i + 1)],
      });
    }

    const data = {
      ...form,
      firstname: form.firstname.toLowerCase(),
      lastname: form.lastname.toLowerCase(),
      email: form.email.toLowerCase(),
      rib,
      mobilePhone: {
        prefix: form['codeCountry'],
        number: form['mobilePhone'],
      },
      modules,
    };
    axios
      .post(`${BASE_URI}user/add`, data)
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setMessage(res.data?.message);
          setSuccess(true);
          setShow(true);
          setForm((f) => ({ ...inputs, id: f.id + 1 }));
        } else {
          setLoading(false);
          setMessage(res.data?.message);
          setSuccess(false);
          setShow(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setMessage("Une erreur s'est produite.");
        setSuccess(false);
        setShow(true);
      });
  };

  if (loading) {
    return <Loader />;
  }
  const civilityData = [
    { label: 'Mr', value: 'mr' },
    { label: 'Mme', value: 'mme' },
    { label: 'Mlle', value: 'mlle' },
  ];

  const handleActive = (data, v) => {
    const newModules = modules.map((m) => {
      if (m.name === data.name) {
        return { ...m, active: v };
      }
      return m;
    });
    setModules(newModules);
  };

  return (
    <>
      <div className='flex items-center justify-between w-full'>
        <ComponentToast
          show={show}
          setShow={setShow}
          message={message}
          success={success}
        />

        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          Informations Utilisateur
        </h3>
      </div>
      <form
        className='mt-5 border-t border-gray-200 w-full flex flex-col items-start justify-start'
        onSubmit={handleSubmit}
      >
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='id'
            type='number'
            name='id'
            value={form['id']}
            onChange={handleChange}
            width='10%'
          />
          <InputLayout
            label='Titre'
            type='text'
            name='title'
            value={form['title']}
            onChange={handleChange}
            width='40%'
          />
          <InputLayout
            label='Code médecin'
            type='text'
            name='doctorCode'
            value={form['doctorCode']}
            onChange={handleChange}
            width='40%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <SelectLayout
            label='Civilité'
            name='civility'
            value={form['civility']}
            onChange={handleChange}
            options={civilityData}
            width='10%'
          />
          <InputLayout
            label='Nom'
            type='text'
            name='lastname'
            requiredBg
            required
            value={form['lastname']}
            onChange={handleChange}
            width='40%'
          />
          <InputLayout
            label='Prénom(s)'
            type='text'
            name='firstname'
            requiredBg
            required
            value={form['firstname']}
            onChange={handleChange}
            width='40%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Matricule'
            type='number'
            name='matricule'
            value={form['matricule']}
            onChange={handleChange}
            width='75%'
          />

          <InputLayout
            label='Date de naissance'
            type='date'
            name='dateOfBirth'
            value={form['dateOfBirth']}
            onChange={handleChange}
            width='20%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Spécialité'
            name='speciality'
            type='text'
            value={form['speciality']}
            onChange={handleChange}
            width='30%'
          />
          <InputLayout
            label='Téléphone Cabinet'
            type='text'
            name='cabinetPhone'
            value={form['cabinetPhone']}
            onChange={handleChange}
            width='30%'
          />
          <InputLayout
            label='Fax'
            type='number'
            name='fax'
            value={form['fax']}
            onChange={handleChange}
            width='30%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Téléphone Privé'
            type='number'
            name='privatePhone'
            value={form['privatePhone']}
            onChange={handleChange}
            width='48%'
          />
          <div
            style={{ width: '48%' }}
            className='flex flex-col items-start justify-start'
          >
            <label className='font-xs text-gray-500'>Tel/Mobile</label>
            <div className='w-full flex items-center'>
              <select
                name='codeCountry'
                className='w-full bg-transparent'
                style={{ width: '25%' }}
                required
                onChange={handleChange}
                value={form['codeCountry']}
              >
                <option selected value='+352'>
                  +352
                </option>
                {Countries.map((country, id) => (
                  <option key={id} value={country.phone}>
                    +{country.phone}
                  </option>
                ))}
              </select>
              <InputLayout
                type='text'
                name='mobilePhone'
                value={form['mobilePhone']}
                onChange={handleChange}
                width='75%'
              />
            </div>
          </div>
        </div>

        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='N°'
            type='number'
            name='number'
            value={form['number']}
            onChange={handleChange}
            width='20%'
          />
          <InputLayout
            label='Rue'
            type='text'
            name='street'
            value={form['street']}
            onChange={handleChange}
            width='75%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Code postal'
            type='number'
            name='zip'
            value={form['zip']}
            onChange={handleChange}
            width='20%'
          />
          <InputLayout
            label='Ville'
            type='text'
            name='city'
            value={form['city']}
            onChange={handleChange}
            width='75%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <div className='flex flex-col items-start w-full '>
            <label className='font-xs text-gray-500'>Pays</label>

            <select
              name='country'
              className='w-full bg-transparent'
              required
              onChange={handleChange}
              value={form['country']}
            >
              <option selected value='Luxembourg'>
                Luxembourg
              </option>
              {Countries.map((country) => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <h3 className='text-lg leading-6 mt-4 font-medium text-gray-900'>
          Informations Bancaire
        </h3>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Bic 1'
            type='number'
            name='bic1'
            value={form['bic1']}
            onChange={handleChange}
            width='20%'
          />
          <InputLayout
            label='Iban 1'
            type='number'
            name='iban1'
            value={form['iban1']}
            onChange={handleChange}
            width='75%'
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Bic 2'
            type='number'
            name='bic2'
            value={form['bic2']}
            onChange={handleChange}
            width='20%'
            required={false}
          />
          <InputLayout
            label='Iban 2'
            type='number'
            name='iban2'
            value={form['iban2']}
            onChange={handleChange}
            width='75%'
            required={false}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Bic 3'
            type='number'
            name='bic3'
            value={form['bic3']}
            onChange={handleChange}
            width='20%'
            required={false}
          />
          <InputLayout
            label='Iban 3'
            type='number'
            name='iban3'
            value={form['iban3']}
            onChange={handleChange}
            width='75%'
            required={false}
          />
        </div>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Bic 4'
            type='number'
            name='bic4'
            value={form['bic4']}
            onChange={handleChange}
            width='20%'
            required={false}
          />
          <InputLayout
            label='Iban 4'
            type='number'
            name='iban4'
            value={form['iban4']}
            onChange={handleChange}
            width='75%'
            required={false}
          />
        </div>
        <h3 className='text-lg leading-6 mt-4 font-medium text-gray-900'>
          Identifiants
        </h3>
        <div className='w-full flex items-center justify-between'>
          <InputLayout
            label='Email'
            type='email'
            name='email'
            value={form['email']}
            requiredBg
            required
            onChange={handleChange}
            width='25%'
          />
          <InputLayout
            label='Mot de passe'
            type='password'
            name='password'
            value={form['password']}
            requiredBg
            required
            onChange={handleChange}
            width='25%'
          />
          <InputLayout
            label="Texte d'en-tête"
            type='text'
            name='headerText'
            value={form['headerText']}
            onChange={handleChange}
            width='40%'
          />
        </div>

        <span className='text-lg leading-4 font-medium text-gray-900 my-6'>
          Modules
        </span>
        <div className='w-full flex flex-col items-start justify-start'>
          {modules.map((module, id) => (
            <div
              className='flex items-center justify-between w-6/12 h-16 bg-white rounded p-3 my-1'
              key={id}
            >
              <span className='font-xs text-gray-700 capitalize'>
                {module.name === 'formsModule'
                  ? 'Formulaires'
                  : module.name === 'dentalModule'
                  ? 'MW Dentiste'
                  : module.name === 'cepha'
                  ? 'Céphalométrique'
                  : "Comparateur d'images"}
              </span>
              <SwitchActive module={module} handleModuleActive={handleActive} />
            </div>
          ))}
        </div>

        <div className='w-full flex items-center justify-end'>
          <button
            type='button'
            onClick={() => history.goBack()}
            className='font-medium text-center text-gray-900 uppercase bg-gray-200 px-6 py-2 border-2 border-gray-200'
          >
            Retour
          </button>

          <button class='inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-blue-700 uppercase transition bg-transparent border-2 border-blue-700 rounded shadow hover:shadow-lg hover:bg-blue-100 focus:outline-none block ml-8'>
            Ajouter
          </button>
        </div>
      </form>
    </>
  );
};

export default withLayout(AddUser);
