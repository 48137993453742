import { Switch, Route } from "react-router-dom";
import withUserConnected from "../../hoc/withUserConnected";
import AddUser from "./AddUser";
import User from "./User";
import Users from "./Users";

function Online() {
  return (
    <Switch>
      <Route exact path="/account/users" component={Users} />
      <Route exact path="/account/user/:slug" component={User} />
      <Route exact path="/account/add" component={AddUser} />
    </Switch>
  );
}

export default withUserConnected(Online);
