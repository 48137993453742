import axios from "axios";
import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { BASE_URI } from "../../../assets/app.config";
import ComponentToast from "../../../assets/helpers/helperToast";
import Logo from "../../../assets/images/logo.svg";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((f) => ({ ...f, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const res = await axios.post(`${BASE_URI}admin/auth`, {
        ...form,
        email: form.email.toLowerCase(),
      });
      if (res.status === 200) {
        setMessage("Vous êtes connecté");
        setSuccess(true);
        setShow(true);
        setLoading(false);
        sessionStorage.setItem("md_token", res.data.token);
        setTimeout(() => history.push("/account/users"), 1000);
      }
    } catch (err) {
      setMessage("Email ou password incorrect.");
      setSuccess(false);
      setShow(true);
      setLoading(false);
    }
  };
  return (
    <div className='min-h-screen  bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8'>
      <ComponentToast
        show={show}
        setShow={setShow}
        message={message}
        success={success}
      />
      <div className='sm:mx-auto  sm:w-full sm:max-w-md'>
        <img className='mx-auto h-12 w-auto' src={Logo} alt='logo' />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Connectez-vous
        </h2>
        <p className='text-center mt-3 text-s'>à votre tableau de bord</p>
      </div>

      <div className='mt-8 w-2/6 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form className='space-y-6' onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'>
                Email address
              </label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  onChange={handleChange}
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'>
                Password
              </label>
              <div className='mt-1'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  onChange={handleChange}
                  autoComplete='current-password'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center'></div>

              <div className='text-sm'>
                <NavLink
                  to='/forget'
                  className='font-medium text-blue-600 hover:text-blue-500'>
                  Mot de passe oublié?
                </NavLink>
              </div>
            </div>

            <div>
              <button
                type='submit'
                disabled={loading}
                className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                {loading ? "Sign in..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
